import type { MainSolutionOptionsObject } from "../../translation-keys/main-solution-option-keys.js";

const MainSolutions: MainSolutionOptionsObject = {
  "Quality": "品質",
  "Manufacturing": "製造",
  "Logbooks": "Logbooks",
  "Clinical": "クリニカルエクセレンス",
  "Product Development": "製品開発",
  "Regulatory": "薬事",
  "Supplier": "サプライヤー",
  "Post-Market": "市販後"
};

export default MainSolutions;
